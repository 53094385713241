<template>
  <div class="search">
    <div class="container">
      <app-card>
        <h1 class="title search__title">Пошук пацієнта</h1>
        <search-form />
      </app-card>
    </div>
  </div>
</template>
<script>
import SearchForm from "./SearchClientForm.vue";
export default {
  components: { SearchForm },
};
</script>

<style scoped lang="scss">
.search {
  margin-top: 17px;
  margin-bottom: 30px;
  &__title {
    margin-bottom: 25px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 22px;
    }
    @media (max-width: 374px) {
      font-size: 22px;
      margin-bottom: 18px;
    }
  }
}
</style>