<template>
  <div class="search__wrap">
    <form class="search__form" @submit.prevent="findPatient">
      <div class="search__form-item" v-for="item in formInit" :key="item.id">
        <label :for="item.id" class="search__form-label">{{
          item.label
        }}</label>
        <base-input :item="item" :id="item.id" v-if="item.elType === 'input'" />
        <date-picker
          v-else-if="item.elType === 'date'"
          v-model="item.model"
          :placeholder="item.placeholder"
          valueType="format"
          class="search__form-date"
        />
      </div>
      <error :error="error" />
      <base-btn>Пошук</base-btn>
    </form>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      error: null,
      formInit: [
        {
          id: "first_name",
          label: "Прізвище",
          elType: "input",
          placeholder: "",
          model: "",
        },
        {
          id: "last_name",
          label: "Ім’я",
          elType: "input",
          placeholder: "",
          model: "",
        },
        {
          id: "middle_name",
          label: "По батькові",
          elType: "input",
          placeholder: "",
          model: "",
        },
        {
          id: "birthdate",
          label: "Дата народження",
          elType: "date",
          placeholder: "Оберіть дату",
          model: "",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
    }),
  },
  methods: {
    ...mapMutations(["setCurrentPatient"]),
    ...mapActions(["searchPatient"]),
    findPatient() {
      let searchPatientData = {};
      this.formInit.map((el) => {
        searchPatientData[el.id] = el.model;
      });
      if (
        searchPatientData.first_name.trim() &&
        searchPatientData.last_name.trim() &&
        searchPatientData.middle_name.trim() &&
        searchPatientData.birthdate.trim()
      ) {
        this.searchPatient(searchPatientData)
          .then((resp) => {
            localStorage.setItem("patient", JSON.stringify(searchPatientData));
            if (resp.data) {
              this.setCurrentPatient(resp.data);
              this.$router.push({ name: "CardPatient" });
            } else {
              this.$router.push({
                name: "Create",
                params: { val: this.formInit },
              });
            }
          })
          .catch((e) => {
            this.error = e.response.data.error_text;
            if (this.error === "token expired") {
              this.$router.replace({ name: "Login" });
            } else {
              setTimeout(() => {
                this.error = null;
                this.loginData.map((el) => (el.model = ""));
              }, 2000);
            }
          });
      } else {
        this.error = "Не всі поля заповнені";
        setTimeout(() => {
          this.error = null;
        }, 2000);
      }
    },
  },
  mounted() {
    localStorage.patient = null;
  },
};
</script>

<style scoped lang="scss">
.search {
  &__wrap {
    max-width: 386px;
    padding: 0 10px;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 350px;
      padding: 0 15px;
    }
  }

  &__form {
    &-item {
      margin-bottom: 16px;
    }
    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    &-date {
      width: 100%;
    }
  }
}
</style>
